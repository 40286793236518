import React, {useState, useEffect} from 'react'
import Layout from '../../components/layout'
import HubspotForm from "react-hubspot-form"
import AOS from 'aos';
import "aos/dist/aos.css";
import EmbedVideoFrame from '../../components/EmbedVideoFrame';
import Helmet from "react-helmet"
const Teams =  ({location}) => {

    const [videoLinkMSTeams, setVideoLinkMSTeams] = useState('')

    const handleClick = () => setVideoLinkMSTeams("https://www.youtube.com/embed/wYsUdE8DcbI")

    useEffect(() => {
        AOS.init({
            once:true
        });
      }, []);
      

  return (
    <Layout location={location}>
         <Helmet>
                <title>Quick and Easy Integrations - Vantage Circle </title>

                <meta name="description" content="Now connect, collaborate and appreciate your peers remotely with the help of Microsoft Teams and Vantage Circle integration." />
                <meta property="twitter:title" content="Microsoft Teams Integration for Corporates - Vantage Circle" />
                <meta name= "robots" content= "noindex" />
                        
                <meta property="twitter:description" content="Now connect, collaborate and appreciate your peers remotely with the help of Microsoft Teams and Vantage Circle integration." />
                        
                <meta property="og:type" content="website"/>
                        
                <meta property="og:url" content="https://www.vantagecircle.com/integration/microsoft-teams/"/>

                <meta property="og:site_name" content="Vantage Circle"/>
                <meta property="og:title" content="Microsoft Teams Integration for Corporates - Vantage Circle" />

                <meta property="og:description" content="Now connect, collaborate and appreciate your peers remotely with the help of Microsoft Teams and Vantage Circle integration." />
                <link rel="canonical" href="https://www.vantagecircle.com/integration/microsoft-teams/"></link>
            </Helmet>

       <section class="w-full flex place-content-center relative bg-indigo-100 overflow-x-hidden py-9 xl:py-0 overflow-y-hidden z-0 px-3 lg:px-0">
        <div class="max-w-7xl grid xl:grid-cols-2 justify-center items-start z-10 lg:px-3 relative">
            <div class="w-full md:w-10/12 xl:w-full mx-auto flex flex-col text-center lg:text-left justify-center xl:items-start items-center xl:mr-3 xl:pt-12 xl:pb-20">
                    <div class="my-2 flex justify-center items-center px-4 py-2 rounded-full bg-indigo-50">
                        <h2 class="text-purple-100 text-sm lato">INTEGRATIONS</h2>
                    </div>
                    <h1 class="text-purple-100 lg:pr-3 xl:w-10/12 text-4xl md:text-4xl xl:text-7xl font-bold text-center xl:text-left">Do more with your<span class="text-orange"> Teams</span> app</h1>
                    <p class="my-8 text-lg xl:text-xl text-purple-100 text-center xl:text-left w-full">Boost collaboration and drive team engagement levels with the help of Microsoft Teams and Vantage Rewards</p>
                    <div className="xl:flex justify-center items-center lg:justify-start w-5/6 md:w-1/3 xl:w-9/12 2xl:w-2/3 cursor-pointer my-8">
                        <a className="vc-new-orange-btn-rounded-full lato rounded-full mb-3 xl:mb-0 xl:mr-2" href="#FormVR">Talk to us</a>
                        <a className="vc-new-white-border-btn-rounded-full lato rounded-full mt-3 xl:mt-0 xl:ml-2" href="#msTeamVideo">Watch video</a>
                    </div>      
                </div>
            <div class="xl:w-5/12 h-80 md:h-500 xl:h-80"></div>
            <div class="absolute -bottom-72 md:scale-90 transform xl:scale-100 md:right-28 lg:right-1/4 xl:-bottom-10 xl:right-0 flex justify-center items-center h-full mt-6 xl:mt-0 pt-4 lg:ml-3 aos-init aos-animate" data-aos="fade-up" data-aos-duration="500" data-aos-delay="600"><img decoding="async" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/04/vantage-rewards-microsoft-teams-1.webp" alt="vantage-rewards-microsoft-teams-1" width="600" /></div>
        </div>
        </section>
        <section className='w-full py-10 xl:py-16 px-5'>
            <div className='mx-auto max-w-7xl'>
                <h2 className= "text-center text-3xl xl:text-5xl max-w-5xl mx-auto py-1">Easy<span className='text-orange'>.</span> Effortless<span className='text-orange'>.</span> Rewarding<span className='text-orange'>.</span></h2>
                <p className='text-center py-1 text-gray-500'>Make recognition easy, effortless, and rewarding for your employees while they collaborate on work</p>
                <div className='grid md:grid-cols-2 gap-6 max-w-4xl mx-auto py-10'>
                    <div className='border rounded-lg p-3 flex py-4 border-gray-light items-center justify-center mb-5'>
                        <div className='flex w-3/12 justify-center item-center'>
                            <img decoding="async" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/04/Increase-rewards.webp" width= "40"/>
                        </div>
                        <div className='flex w-9/12 justify-start items-center'>
                            <p className='m-0 text-gray-500'>Increase adoption of Rewards and Recognition</p>
                        </div>
                    </div>
                    <div className='border rounded-lg p-3 flex py-4 border-gray-light items-center justify-center mb-5'>
                        <div className='flex w-3/12 justify-center item-center'>
                            <img decoding="async" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/04/impactful-reward.webp" width= "40"/>
                        </div>
                        <div className='flex w-9/12 justify-start items-center'>
                            <p className='m-0 text-gray-500'>Make your Rewards and Recogntion more powerful</p>
                        </div>
                    </div>
                    <div className='border rounded-lg p-3 flex py-4 border-gray-light items-center justify-center mb-5'>
                        <div className='flex w-3/12 justify-center item-center'>
                            <img decoding="async" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/04/Save-time.webp" width= "40"/>
                        </div>
                        <div className='flex w-9/12 justify-start items-center'>
                            <p className='m-0 text-gray-500'>Save time with reduced app shuffle and easy access</p>
                        </div>
                    </div>
                    <div className='border rounded-lg p-3 flex py-4 border-gray-light items-center justify-center mb-5'>
                        <div className='flex w-3/12 justify-center item-center'>
                            <img decoding="async" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/05/msteam_changemanagement_icon.png" width= "40"/>
                        </div>
                        <div className='flex w-9/12 justify-start items-center'>
                            <p className='m-0 text-gray-500'>Leave behind the hassle of change management</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <section class="w-full flex place-content-center relative overflow-x-hidden py-0 lg:py-8 overflow-y-hidden rewards-bg-1 px-3 lg:px-0" id="FormVR">
            <div class="lg:flex max-w-7xl px-3">
                <div class="card-1 w-full mb-10 md:mb-0 mt-5 xl:mt-0 lg:w-1/2 xl:w-2/3 2xl:w-2/3">
                    <h2 class="text-3xl xl:text-5xl text-center xl:text-left text-gray-250 font-bold pt-5 mb-8 md:px-12 lg:px-0 xl:w-8/12">Making Life Easy for <span class="relative">HRs<img decoding="async" loading="lazy" class="absolute -bottom-1 right-0 xl:right-0" src="https://res.cloudinary.com/vantagecircle/image/upload/v1648619895/gatsbycms/uploads/2022/03/hero-strokesvg.svg" /></span></h2>
                    <p class="mb-8 text-center text-xl md:text-left mt-2 mb-4 xl:w-8/12 text-gray-500">
                        Quick glimpse into our Rewards & Recognition Platform.
                    </p>
                    <div className='mt-12 mb-8'>
                        <div className='flex w-full items-center'>
                            <img src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/03/dashboard-interface-copy-4.png" width= "46" height="20" />
                            <p className='m-0 pl-5 text-gray-500 text-xl'>Intuitive Admin Dashboard For Complete Control</p>
                        </div>
                    </div>
                    <div className='my-8'>
                        <div className='flex w-full items-center'>
                            <img src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/03/AUtomated-recognition.png" width= "46" height="20" />
                            <p className='m-0 pl-5 text-gray-500 text-xl'>Automated Recognition For Birthdays And Work Anniversaries</p>
                        </div>
                    </div>
                    <div className='my-8'>
                        <div className='flex w-full items-center'>
                            <img src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/03/Announcements.png" width= "46" height="20" />
                            <p className='m-0 pl-5 text-gray-500 text-xl'>Company Wide Announcements</p>
                        </div>
                    </div>
                    <div className='my-8'>
                        <div className='flex w-full items-center'>
                            <img src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/03/Awards-Badges.png" width= "46" height="20" />
                            <p className='m-0 pl-5 text-gray-500 text-xl'>Customized Awards & Badges</p>
                        </div>
                    </div>
                    <div className='my-8'>
                        <div className='flex w-full items-center'>
                            <img src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/03/Rewards-Catalog.png" width= "46" height="20" />
                            <p className='m-0 pl-5 text-gray-500 text-xl'>Global Rewards Catalog</p>
                        </div>
                    </div>
                    <div className='my-8'>
                        <div className='flex w-full items-center'>
                            <img src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/03/15-Languages.png" width= "46" height="20" />
                            <p className='m-0 pl-5 text-gray-500 text-xl'>Available In 15+ Languages</p>
                        </div>
                    </div>
                    <div className='my-8'>
                        <div className='flex w-full items-center'>
                            <img src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/03/CUstomer-support.png" width= "46" height="20" />
                            <p className='m-0 pl-5 text-gray-500 text-xl'>Best-In-Class Customer Support</p>
                        </div>
                    </div>
                </div>
                <div className='lg:w-1/2 xl:w-5/12 2xl:w-1/3 flex items-start xl:mt-10'>
                    <div id="form" className="card-1 w-full mb-10 md:mb-0 mt-10 xl:mt-0 flex flex-col place-items-center rounded-lg px-6 py-10 rewards-bg-2">
                        <h2 class="text-2xl lato text-gray-250 pt-5 mb-8 md:px-12 lg:px-0 text-center">
                            Schedule a demo
                        </h2>
                        <p class="text-sm mb-8 text-center mt-2">
                            Get a Personalized walkthrough of Vantage Rewards from a friendly member of our team
                        </p>
                        <div class="w-full flex justify-center items-center lato rewards team">
                            <HubspotForm
                            portalId="6153052"
                            formId="fd7447a2-d725-4bb1-aea7-676ff9b61d77"
                            onSubmit={() => console.log("Submit!")}
                            onReady={form => console.log("Form ready!")}
                            loading={<div>Loading...</div>}
                            />
                        </div>
                        <p class="text-sm text-center">
                            We safeguard your{" "}
                            <a href="/privacy-policy/" class="text-orange">
                            Privacy
                            </a>
                        </p>
                        <script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/embed/v2.js"></script>
                    </div>
                </div>
            </div>
        </section> */}

        <section class="w-full flex place-content-center relative overflow-x-hidden py-0 lg:py-16 2xl:py-20 overflow-y-hidden rewards-bg-1 px-3 lg:px-0" id="FormVR">
            <div class="lg:flex max-w-7xl px-3">
                <div class="card-1 w-full mb-10 md:mb-0 mt-5 xl:mt-0 lg:w-1/2 xl:w-2/3 2xl:w-2/3 flex flex-col xl:justify-center">
                    <h2 class="text-3xl xl:text-5xl text-center lg:text-left text-gray-250 font-bold pt-5 mb-8 md:px-12 lg:px-0">Please tell us more about your requirements.</h2>
                    <div className='flex justify-center lg:justify-end lg:w-11/12 2xl:w-full xl:pr-5 2xl:pr-10 transform rotate-90 lg:rotate-0'><img loading='lazy' src='https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/11/Group-2-2.webp' height="110" width="110" /></div>
                    {/* <ul class="list-inside orangebullets text-gray-250 lato text-lg xl:text-xl md:text-md 2xl:text-lg md:px-12 lg:px-0 w-full lg:w-8/12">
                        <li class="py-1">Are you experiencing a lack of engagement and motivation among your employees?</li>
                        <li class="py-1">Is toxic culture something you are trying to avoid?</li>
                        <li class="py-1">No way for your workforce to be rewarded and recognized socially in real-time?</li>
                        <li class="py-1">Is there no meaningful social interaction among employee peer groups?</li>
                        <li class="py-1">Do you already have a rewards and recognition program, but it is not centralized globally?</li>
                        <li class="py-1">Do you find it challenging to reward your dispersed workforce that is diverse and multi-lingual?</li>
                    </ul> */}
                </div>
                <div class="card-1 w-full mb-10 md:mb-0 mt-10 xl:mt-0 lg:w-1/2 xl:w-5/12 2xl:w-1/3 flex flex-col place-items-center rounded-lg px-6 py-10 rewards-bg-2">
                    <h2 class="text-2xl lato text-gray-250 pt-5 mb-8 md:px-12 lg:px-0 text-center">You are now one step closer to fostering a dynamic culture of recognition</h2>
                    <div class="w-full flex justify-center items-center lato team">
                        <div class = "flex justify-center items-center">
                            <HubspotForm
                            portalId='6153052'
                            formId='60bb734f-4751-4884-a680-003fb7c07ccf'
                            onSubmit={() => console.log('Submit!')}
                            onReady={(form) => console.log('Form ready!')}
                            loading={<div>Loading...</div>}
                            />
                        </div>
                    </div>
                    <a href="/privacy-policy/" class="text-gray-250 underline lato text-sm">Read our Privacy Policy</a>               
                </div>
            </div>
        </section>

        <section className='bg-purple-100'>
        <div class="container mx-auto px-6 py-10 md:py-20">
                    <div class="text-center">
                        <h2 class="text-2xl md:text-3xl xl:text-5xl">
                        You can do all this and more with our <br/>Microsoft Teams Integration
                        </h2>
                    </div>
                    <div class="max-w-7xl mx-auto mt-10 md:mt-20 grid grid-cols-1 gap-5 md:gap-9 sm:grid-cols-2 md:grid-cols-3 items-center">
                        <div class="flex h-full">
                            <div class="p-3 w-12 h-12 rounded shadow mb-4 flex-shrink-0">
                                <img className='pt-1' src='https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/03/tick.webp' />
                            </div>
                            <div class="ml-4">
                                <h3 class="text-2xl xl:text-3xl mb-3">
                                Appreciate
                                </h3>
                                <p class="box-subtitle-1">
                                Now appreciate your peers through non-monetary awards with different badges to boost their morale.
                                </p>
                            </div>
                        </div>
                        <div class="flex h-full">
                             <div class="p-3 w-12 h-12 rounded shadow mb-4 flex-shrink-0">
                                <img className='pt-1' src='https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/03/tick.webp' />
                            </div>
                            <div class="ml-4">
                                <h3 class="text-2xl xl:text-3xl mb-3">
                                Nominate
                                </h3>
                                <p class="box-subtitle-1">
                                Nominate your peers for a job well done through monetary awards and give away Vantage points at your convenience.
                                </p>
                            </div>
                        </div>
                        <div class="flex h-full">
                            <div class="p-3 w-12 h-12 rounded shadow mb-4 flex-shrink-0">
                                <img className='pt-1' src='https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/03/tick.webp' />
                            </div>
                            <div class="ml-4">
                                <h3 class="text-2xl xl:text-3xl mb-3">
                                Social Feed
                                </h3>
                                <p class="box-subtitle-1">
                                    Elevate the employee experience levels with our user-friendly and dynamic social feed on the go.
                                </p>
                            </div>
                        </div>
                        <div class="flex h-full">
                            <div class="p-3 w-12 h-12 rounded shadow mb-4 flex-shrink-0">
                                <img className='pt-1' src='https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/03/tick.webp' />
                            </div>
                            <div class="ml-4">
                                <h3 class="text-2xl xl:text-3xl mb-3">
                                    Approve and Decline Nominations
                                </h3>
                                <p class="box-subtitle-1">
                                    Managers can approve or decline any nomination to maintain a smoother rewards process.
                                </p>
                            </div>
                        </div>
                        <div class="flex h-full">
                            <div class="p-3 w-12 h-12 rounded shadow mb-4 flex-shrink-0">
                                <img className='pt-1' src='https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/03/tick.webp' />
                            </div>
                            <div class="ml-4">
                                <h3 class="text-2xl xl:text-3xl mb-3">
                                Leaderboard
                                </h3>
                                <p class="box-subtitle-1">
                                Easy accessibility of the leaderboard to keep check of the employees that have gained the highest number of awards.
                                </p>
                            </div>
                        </div>
                        <div class="flex h-full">
                            <div class="p-3 w-12 h-12 rounded shadow mb-4 flex-shrink-0">
                                <img className='pt-1' src='https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/03/tick.webp' />
                            </div>
                            <div class="ml-4">
                                <h3 class="text-2xl xl:text-3xl mb-3">
                                Accessibility
                                </h3>
                                <p class="box-subtitle-1">
                                Users have the flexibility to access the integrated Microsoft Teams through the website and mobile application.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="mt-5 sm:mt-8 md:mt-10 flex justify-center">
                        <div>
                            <a href="#FormVR" class="vc-new-orange-btn-rounded-full">
                                Talk to us
                            </a>
                        </div>
                    </div>
                </div>
        </section>

        <section class="w-full flex flex-col place-content-center relative overflow-x-hidden py-9 xl:pt-10 xl:pb-0 2xl:pt-5 overflow-y-hidden z-0 px-3 lg:px-8 xl:px-0">
            <div className='max-w-7xl mx-auto'>
                <h2 class="text-gray-250 text-3xl md:text-4xl xl:text-5xl 2xl:text-5xl font-bold text-center xl:text-left font-bold my-12 w-full xl:w-11/12">What makes us a <span className= "text-orange">preferred </span>Microsoft Partner for Rewards and Recognition?<span class="text-orange"></span></h2>
                <div class="xl:flex gap-10 justify-center items-start w-full mb-0 lg:my-8 relative max-w-7xl xl:pt-10">
                    <div className='w-full xl:w-4/12 my-5'>
                        <div className='border rounded-lg p-3 flex py-4 border-gray-light items-center justify-center mb-5'>
                            <div className='flex w-3/12 justify-center item-center'>
                                <img decoding="async" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/03/Pinned-app.webp" width= "40"/>
                            </div>
                            <div className='flex w-9/12 justify-start items-center'>
                                <p className='m-0 text-gray-500'>Personal scope of a pinned app</p>
                            </div>
                        </div>
                        <div className='border rounded-lg p-3 flex py-4 border-gray-light items-center justify-center my-5'>
                            <div className='flex w-3/12 justify-center item-center'>
                                <img decoding="async" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/03/Tailor-made-app.webp" width= "40"/>
                            </div>
                            <div className='flex w-9/12 justify-start items-center'>
                                <p className='m-0 text-gray-500'>Full app experience in Teams</p>
                            </div>
                        </div>
                        <div className='border rounded-lg p-3 flex py-4 border-gray-light items-center justify-center mt-5'>
                            <div className='flex w-3/12 justify-center item-center'>
                                <img decoding="async" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/05/msteam_Interactive-and-easy-to-use_icon.png" width= "40"/>
                            </div>
                            <div className='flex w-9/12 justify-start items-center'>
                                <p className='m-0 text-gray-500'>Interactive and easy to use</p>
                            </div>
                        </div>
                        <div className='border rounded-lg p-3 flex py-4 border-gray-light items-center justify-center my-5'>
                            <div className='flex w-3/12 justify-center item-center'>
                                <img decoding="async" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/03/ux.png" width= "40"/>
                            </div>
                            <div className='flex w-9/12 justify-start items-center'>
                                <p className='m-0 text-gray-500'>Consistent User Experience</p>
                            </div>
                        </div>
                        <div className='border rounded-lg p-3 flex py-4 border-gray-light items-center justify-center mt-5'>
                            <div className='flex w-3/12 justify-center item-center'>
                                <img decoding="async" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/03/security-compliant.png" width= "40"/>
                            </div>
                            <div className='flex w-9/12 justify-start items-center'>
                                <p className='m-0 text-gray-500'>Microsoft 365 security compliant</p>
                            </div>
                        </div>
                      
                    </div>
                    <div class="w-10/12 mx-auto xl:w-7/12 flex justify-center md:mt-16 xl:my-0 items-center z-10"><img decoding="async" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/03/overview-IMG.webp" width="900" /></div>
                {/* <div class="z-0 hidden lg:block absolute -left-24 lg:-top-28 xl:-top-20 2xl:top-0"><img decoding="async" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/rewards-stroke-1.png" /></div> */}
                </div>
            </div>
        </section>
        <section className='w-full my-10 px-3 xl:px-0'>
            <div className='max-w-7xl mx-auto'>
                <a href = "https://appsource.microsoft.com/en-us/product/office/WA200002159?exp=ubp8">
                    <div className='max-w-7xl bg-indigo-100 rounded-xl p-9 py-10 xl:flex mx-auto'>
                        <div className='w-full xl:w-1/2'><p className='text-purple-100 text-center xl:text-left text-2xl m-0 xl:text-3xl'>Find Vantage Rewards on Microsoft AppSource.</p></div>
                        <div className='w-full xl:w-1/2 grid justify-center xl:justify-end mt-5 xl:mt-0'><img src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/03/appsource-badge.webp" width="250" /></div>
                    </div>
                </a>
            </div>
        </section>
        
        {/* <section className='w-full my-24 md:mb-0 xl:mt-24 px-2' id="msTeamVideo">
            <div className='max-w-7xl mx-auto'>
                <h2 className='text-center max-w-4xl mx-auto text-3xl xl:text-5xl'>See how Microsoft Teams work with Vantage Rewards</h2>
                <div class="container my-16 md:mb-0 lg:my-16 msteamsVid">              
                    <div className='amazonpaidvideo mx-auto rounded-xl shadow-xl md:shadow-none xl:shadow-xl border border-white relative'>
                        <iframe className={(`youtube-videos absolute left-0 top-0 right-0 bottom-0 ` + ((videoLinkMSTeams.length > 0) ? `block` : `hidden`))} loading="lazy" width="560" height="500" src={videoLinkMSTeams} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen=""></iframe>
                        <img className= {(`absolute left-0 top-0 transform xl:-translate-y-12 right-0 bottom-0 h-52 md:h-auto md:w-10/12 xl:w-full mx-auto ` + ((videoLinkMSTeams.length > 0) ? `hidden` : `block`))} onClick={handleClick} src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/03/play-btn-1.webp" />
                    </div>
                </div>
            </div>
        </section> */}

        <section>

            <EmbedVideoFrame 
            videoLink={"https://www.youtube.com/embed/wYsUdE8DcbI"} 
            title={"See how Microsoft Teams work with Vantage Rewards"} 
            thumbnail = {"https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/03/play-btn-1.webp"} />

        </section>
        <section className='w-10/12 md:w-4/12 xl:w-2/12 mx-auto my-10 xl:mb-16'>
            <a href='#FormVR' className='vc-new-orange-btn-rounded-full lato rounded-full mb-3 xl:mb-0 xl:mr-2 mx-auto'>Talk to us</a>
        </section>

        <section class="w-full flex place-content-center relative overflow-x-hidden py-9 xl:py-0 overflow-y-hidden z-0 px-3 lg:px-0" style={{ backgroundColor: '#feefe2' }}>
        <div class="max-w-7xl grid xl:grid-cols-2 justify-center items-start z-10 lg:px-3 relative">
            <div class="w-full md:w-10/12 xl:w-full mx-auto flex flex-col text-center lg:text-left justify-center xl:items-start items-center xl:mr-3 xl:pt-12 xl:pb-20">
                    <h1 class="lg:pr-3 xl:w-full text-3xl md:text-4xl xl:text-5xl font-bold text-center xl:text-left">See how Microsoft Teams help clients<span class="text-orange"></span></h1>
                    <p class="my-5 text-lg xl:text-xl text-gray-500 text-center xl:text-left w-full">Download our case study</p>
                    <div className="xl:flex justify-center items-center lg:justify-start w-5/6 lg:w-full xl:w-1/2 2xl:w-1/2 cursor-pointer my-4">
                        <a className="vc-new-orange-btn-rounded-full lato rounded-full mb-3 xl:mb-0 xl:mr-2" href="https://www.vantagecircle.com/case-study/it-consultancy-giant-increased-its-recognition-efforts-with-vantage-rewards/">Download Now</a>
                    </div>      
                </div>
            <div class="xl:w-5/12 h-80 md:h-96 xl:h-64"></div>
            <div class="absolute -bottom-48 md:scale-90 transform xl:scale-100 md:right-28 lg:right-1/4 xl:-bottom-16 xl:right-0 flex justify-center items-center h-full mt-6 xl:mt-0 lg:ml-3 aos-init aos-animate" data-aos="fade-up" data-aos-duration="500" data-aos-delay="600"><img decoding="async" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/03/case-study-mockup.png" alt="Vantage Rewards" width="400" /></div>
        </div>
        </section>
        
    {/* <section class="bottom-cta m-6 md:m-0 relative z-1">
        <div class="container max-w-5xl bg-purple-300 rounded-lg shadow-xl p-10 md:p-14 relative z-9 overflow-hidden my-20">
            <div class="bottom-cta-content text-center text-white">
                <div class="section-title-white">Want to Learn More About Integration?</div>
                <div class="mt-5 sm:mt-6 md:mt-6 flex justify-center">
                    <div>
                        <a class="vc-ghost-btn-cta-banner" href="/contact-us/">Contact Us</a>
                    </div>
                </div>
            </div>
            <img loading="lazy" class="cta-dot-clip" src="https://res.cloudinary.com/vantagecircle/image/upload/v1622890994/gatsbycms/uploads/2021/06/circle-white-compressed.png" alt="VC white dots" width="90" height="90" />
            <img loading="lazy" class="cta-flower-clip" src="https://res.cloudinary.com/vantagecircle/image/upload/v1622874257/gatsbycms/uploads/2021/06/vc-flower.svg" alt="VC flower" width="150" height="150" />
        </div>                         
    </section> */}
    </Layout>
  )
}

export default Teams